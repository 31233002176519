import html2pdf from 'html2pdf.js';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { InvoicesService } from '../../invoices.service';
import { MappedBillingDocumentWithPaymentReceiptData } from '../../types';

@Component({
  selector: 'rwa-bulk-invoice-print',
  templateUrl: './bulk-invoice-print.component.html',
  styleUrls: ['./bulk-invoice-print.component.scss'],
})
export class BulkInvoicePrintComponent implements OnInit {
  invoice: MappedBillingDocumentWithPaymentReceiptData;

  isCreditNote: boolean;

  isBulkOperation: boolean;

  html2pdfOptions = {
    margin: 0.15,
    filename: `${this.getInvoiceId}.pdf`,
    image: { type: 'jpeg', quality: 0.5 },
    html2canvas: { scale: 2 },
    jsPDF: {
      unit: 'cm',
      format: 'a4',
      orientation: 'portrait',
      precision: 32,
    },
  };

  invoicesWithErrors: { id: string; error: string }[] = [];

  index = 0;

  idList: string[] = [];

  invoices: MappedBillingDocumentWithPaymentReceiptData[] = [];

  constructor(
    private route: ActivatedRoute,
    private invoicesService: InvoicesService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fetchDataAndGenerateInvoices();
  }

  async fetchDataAndGenerateInvoices(): Promise<void> {
    const snapshot = this.route?.snapshot;
    if (!snapshot) throw new Error('Route snapshot is undefined');

    const { paramMap, url, queryParamMap } = snapshot;
    const ids = paramMap?.get('id');
    const batchSize = queryParamMap?.get('batchSize')
      ? parseInt(queryParamMap?.get('batchSize'), 10)
      : 200;

    console.log(
      'Ids length is: ',
      ids?.split(',')?.length,
      ' - batch size is ',
      batchSize,
    );

    this.idList = ids.split(',');
    const totalBatches = Math.ceil(this.idList.length / batchSize);

    if (
      ids &&
      (url[0].path === 'invoice-print' || url[0].path === 'credit-note-print')
    ) {
      this.isBulkOperation = true;
      this.isCreditNote = url[0].path === 'credit-note-print';

      for (let batchIndex = 0; batchIndex < totalBatches; batchIndex++) {
        const batchIds = this.idList.slice(
          batchIndex * batchSize,
          (batchIndex + 1) * batchSize,
        );

        const fetchPromises = batchIds.map((id) =>
          url[0].path === 'invoice-print'
            ? this.fetchInvoiceData(id.trim())
            : this.fetchCreditNoteData(id.trim()),
        );
        // eslint-disable-next-line no-await-in-loop
        await Promise.all(fetchPromises);

        for (const id of batchIds) {
          const invoiceIndex = this.invoices.findIndex(
            (invoice) => invoice.id === id.trim(),
          );
          if (invoiceIndex === -1) {
            console.log(`${id} - Not found before downloadInLoop`);
            // eslint-disable-next-line no-continue
            continue;
          }

          this.invoice = { ...this.invoices[invoiceIndex] };

          // eslint-disable-next-line no-await-in-loop
          await this.downloadInLoop(this.invoice.id, invoiceIndex);
        }
      }
    }
  }

  delay(ms: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  async fetchInvoiceData(id: string): Promise<void> {
    try {
      const response = await firstValueFrom(
        this.invoicesService.getInvoiceById(id),
      );
      this.invoices = [...this.invoices, response];
    } catch (err) {
      this.invoicesWithErrors = [
        ...this.invoicesWithErrors,
        { id, error: `${err?.status} - ${err.error?.message}` },
      ];
      console.error('Error fetching invoice', err);
    }
  }

  async fetchCreditNoteData(id: string): Promise<void> {
    try {
      const response = await firstValueFrom(
        this.invoicesService.getCreditNoteById(id),
      );
      this.invoices = [...this.invoices, response];
    } catch (err) {
      this.invoicesWithErrors = [
        ...this.invoicesWithErrors,
        { id, error: `${err.status} - ${err.error.message}` },
      ];
      console.error('Error fetching invoice', err);
    }
  }

  async downloadInLoop(id: string, index: number): Promise<void> {
    console.time('pdf generation');

    const waitForElementToBeVisible = async (): Promise<HTMLElement | null> => {
      const timeout = 10000;
      const interval = 100;
      const endTime = Date.now() + timeout;

      while (Date.now() < endTime) {
        const element = document.getElementById('entire-thing');
        if (element) return element;
        // eslint-disable-next-line no-await-in-loop
        await this.delay(interval);
      }

      return null;
    };

    try {
      const element = await waitForElementToBeVisible();

      if (element) {
        this.html2pdfOptions.filename = `${this.invoices[index].sequencedId}.pdf`;

        await html2pdf(element, this.html2pdfOptions);

        this.index += 1;
        this.invoice = null;
        console.timeEnd('pdf generation');
        await this.delay(1000);
      } else {
        console.log('Element not found within the timeout period.');
      }
    } catch (error) {
      console.error(
        `Error occurred during PDF Generation for invoice ${id}: `,
        error,
      );
    }
  }

  copyErrorIds(): void {
    const el = document.createElement('textarea');
    el.value = this.invoicesWithErrors.map((invoice) => invoice.id).join(',');
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  get getInvoiceId(): string {
    if (!this.invoice) return '';

    return (
      this.invoice.oldSequencedId || this.invoice.sequencedId || this.invoice.id
    );
  }
}
