import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';
import { catchError, finalize, throwError } from 'rxjs';
import { Router } from '@angular/router';
import {
  BillingDocumentLineItem,
  CreateCreditNoteResponseDTO,
  CreditNoteLineItemDTO,
  CreditNoteRequest,
  CreditNoteRequestForPartiallyPaidInvoice,
  IssueCreditNoteDTO,
  MappedBillingDocument,
} from '../../../../accounts/types';
import { AccountService } from '../../../../accounts/account.service';
import { CustomToastService } from '../../../services/custom-toast.service';
import { CalculatorService } from '../../../services/calculator.service';
import { InvoiceZatcaStatusConstantOnFE } from '../../../types';
import { IssueCreditNoteFlowType } from '../../../enums/issue-credit-note-flowtype.enum';
import { PopupData } from '../../../types/popup-data.interface';
import { CreditNoteTypes } from '../../../constants/constants';

enum RefundValue {
  Full = 'full',
  Prorated = 'prorated',
}

@Component({
  selector: 'rwa-issue-credit-note-v2',
  templateUrl: './issue-credit-note-v2.component.html',
  styleUrl: './issue-credit-note-v2.component.scss',
})
export class IssueCreditNoteV2Component implements OnInit, OnChanges {
  REFUND_VALUE_CONST = RefundValue;

  constructor(
    readonly accountService: AccountService,
    readonly customToastService: CustomToastService,
    readonly router: Router,
  ) {}

  @Input() issueCreditNoteInput: {
    dialogVisible: boolean;
    email: string;
    flowType: IssueCreditNoteFlowType;
    invoiceData: MappedBillingDocument;
    subscriptionId?: string;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() closeDialog = new EventEmitter<any>();

  @Output() issueCreditNoteSuccess = new EventEmitter<void>();

  @Input({ required: true }) isPartial: boolean;

  formGroup!: FormGroup;

  creditNoteIssueDate: Date = new Date();

  issuingCreditNote: boolean = false;

  invoiceData: IssueCreditNoteDTO = {
    subtotal: 0,
    discount: 0,
    promotions: 0,
    tax: 0,
    total: 0,
  };

  proratedCreditNote = undefined;

  loading = false;

  productList: CreditNoteLineItemDTO[] = [];

  refundOptions: SelectItem[] = [
    { label: 'Full Refund', value: RefundValue.Full },
    { label: 'Prorated Refund', value: RefundValue.Prorated },
  ];

  refundReasonsDropdownItems: SelectItem[] = [
    { label: 'New Subscription Refund', value: 'New Subscription Refund' },
    { label: 'Renew Subscription Refund', value: 'Renew Subscription Refund' },
    { label: 'New Invoice Cancellation', value: 'New Invoice Cancellation' },
    {
      label: 'Renew Invoice Cancellation',
      value: 'Renew Invoice Cancellation',
    },
    { label: 'Not Paid', value: 'Not Paid' },
    { label: 'Order Change', value: 'Order Change' },
    { label: 'Order Refund', value: 'Order Refund' },
    { label: 'Order Cancellation', value: 'Order Cancellation' },
    { label: 'Retrieve Device', value: 'Retrieve Device' },
  ];

  //  can remove
  refundTypesDropdownItems: SelectItem[] = [
    { label: 'Adjustment Credit Note', value: CreditNoteTypes.Adjustment },
    { label: 'Refundable Credit Note', value: CreditNoteTypes.Refundable },
  ];

  ngOnInit(): void {
    this.initializeForm();
    this.handleDropdownState();
    this.updateValues();
    this.listenToFormChanges();
  }

  listenToFormChanges(): void {
    this.formGroup.valueChanges.subscribe((formVal) => {
      const { refundValue } = formVal;

      if (refundValue === RefundValue.Prorated) {
        this.getProratedData();
      } else {
        this.updateValues();
      }
    });
  }

  getProratedData(): void {
    if (this.loading) return;
    const { subscriptionId, id } = this.issueCreditNoteInput.invoiceData;
    const body = {
      subscriptionId,
      invoiceId: id,
    };

    if (!this.proratedCreditNote) {
      this.loading = true;
      this.accountService
        .getProratedCreditNoteEstimate(body)
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
          catchError((error) => {
            this.formGroup.controls.refundValue.setValue(RefundValue.Full);
            this.customToastService.error(error.error.message);
            return throwError(() => error);
          }),
        )
        .subscribe((res) => {
          const refundable =
            res.find(
              (cn) => cn.type === CreditNoteTypes.Refundable.toLowerCase(),
            ) ?? null;
          const adjustment =
            res.find(
              (cn) => cn.type === CreditNoteTypes.Adjustment.toLowerCase(),
            ) ?? null;

          this.proratedCreditNote = { refundable, adjustment };

          this.updateValues();
        });
    } else {
      this.updateValues();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.issueCreditNoteInput &&
      changes.issueCreditNoteInput.currentValue.dialogVisible === true
    ) {
      this.handleDropdownState();
      this.updateValues();
    }
  }

  initializeForm(): void {
    this.formGroup = new FormGroup({
      refundValue: new FormControl(RefundValue.Full, Validators.required),
      notes: new FormControl(null, Validators.maxLength(500)),
      refundReason: new FormControl(null, Validators.required),
      refundType: new FormControl(
        null,
        this.isPartial ? Validators.required : null,
      ),
    });
  }

  handleDropdownState(): void {
    const plan = this.issueCreditNoteInput?.invoiceData?.lineItems?.find(
      (item) => item.type === 'plan',
    );

    if (plan) {
      this.refundReasonsDropdownItems = this.refundReasonsDropdownItems.map(
        (item) =>
          item.label === 'Not Paid' && item.value === 'Not Paid'
            ? { ...item, disabled: true }
            : item,
      );
    }

    if (
      this.issueCreditNoteInput.invoiceData.zatcaStatus ===
      InvoiceZatcaStatusConstantOnFE.Rejected
    ) {
      const reason =
        this.issueCreditNoteInput.invoiceData.invoice_type === 'B2B'
          ? 'ZATCA Rejected Invoice'
          : 'ZATCA Failure';
      this.refundReasonsDropdownItems.push({
        label: reason,
        value: reason,
      });
      this.formGroup.get('refundReason')?.setValue(reason);
      this.formGroup.get('refundReason')?.disable();
    } else {
      this.refundTypesDropdownItems[0].disabled =
        this.issueCreditNoteInput.invoiceData.adjustment_credit_notes.length >
        0;
      this.refundTypesDropdownItems[1].disabled =
        this.issueCreditNoteInput.invoiceData.issued_credit_notes.length > 0;
      this.formGroup.get('refundReason')?.enable();
      this.formGroup.get('refundType')?.enable();
    }
  }

  handleIssueCreditNote(): void {
    if (this.isPartial) {
      if (this.refundValue === RefundValue.Prorated)
        this.issuePartialProratedCreditNote();
      else this.issuePartialCreditNote();
    } else {
      this.issueCreditNote();
    }
  }

  issuePartialProratedCreditNote(): void {
    const { id, lineItems } = this.issueCreditNoteInput.invoiceData;
    const plan = lineItems.find((item) => item.type === 'plan');
    const payload: CreditNoteRequest = {
      reference_invoice_id: id,
      plan_id: plan?.id,
      date: Math.floor(new Date(this.creditNoteIssueDate).getTime() / 1000),
      create_reason_code: this.refundReason,
      customer_notes: this.notes.value,
    };

    this.issuingCreditNote = true;
    this.accountService
      .generatePartialProratedCreditNote(payload)
      .pipe(
        finalize(() => {
          this.issuingCreditNote = false;
        }),
        catchError((error) => {
          this.customToastService.error(error.message);
          return throwError(() => error);
        }),
      )
      .subscribe((response) => {
        this.onSuccess(plan, response);
      });
  }

  issuePartialCreditNote(): void {
    const { id, lineItems } = this.issueCreditNoteInput.invoiceData;
    const plan = lineItems.find((item) => item.type === 'plan');
    const payload: CreditNoteRequestForPartiallyPaidInvoice = {
      reference_invoice_id: id,
      plan_id: plan?.id,
      date: Math.floor(new Date(this.creditNoteIssueDate).getTime() / 1000),
      create_reason_code: this.refundReason,
      customer_notes: this.notes.value,
      creditNoteType: this.refundType,
    };

    this.issuingCreditNote = true;
    this.accountService
      .issueCreditNoteForPartiallyPaidInvoice(payload)
      .subscribe({
        next: (response: CreateCreditNoteResponseDTO) => {
          this.onSuccess(plan, response);
        },
        error: (err) => {
          this.issuingCreditNote = false;
          this.customToastService.error(err.message);
        },
      });
  }

  issueCreditNote(): void {
    const { id, lineItems } = this.issueCreditNoteInput.invoiceData;
    const plan = lineItems.find((item) => item.type === 'plan');
    const payload: CreditNoteRequest = {
      reference_invoice_id: id,
      plan_id: plan?.id,
      date: Math.floor(new Date(this.creditNoteIssueDate).getTime() / 1000),
      create_reason_code: this.refundReason,
      customer_notes: this.notes.value,
      isProratedCreditNote: this.refundValue === RefundValue.Prorated,
    };

    this.issuingCreditNote = true;
    this.accountService.issueCreditNote(payload).subscribe({
      next: (response: CreateCreditNoteResponseDTO) => {
        this.onSuccess(plan, response);
      },
      error: (err) => {
        this.issuingCreditNote = false;
        this.customToastService.error(err.message);
      },
    });
  }

  setProductList(): void {
    if (this.refundValue === RefundValue.Full)
      this.productList = this.issueCreditNoteInput.invoiceData.lineItems.map(
        (item) => ({
          name: item.name,
          quantity: item.quantity,
          adjustedPrice: CalculatorService.divide(item.unitAmount, 100),
          totalPrice: CalculatorService.divide(
            item.unitAmount * item.quantity,
            100,
          ),
        }),
      );
    else
      this.productList = this.proratedCreditNote.refundable?.line_items.map(
        (item, index) => ({
          name: this.issueCreditNoteInput.invoiceData.lineItems[index].name,
          quantity: item.quantity,
          adjustedPrice: CalculatorService.divide(item.unit_amount, 100),
          totalPrice: CalculatorService.divide(item.amount, 100),
        }),
      );
  }

  setInvoiceData(): void {
    if (this.refundValue === RefundValue.Full)
      this.invoiceData = {
        subtotal: CalculatorService.divide(
          this.issueCreditNoteInput.invoiceData.subTotal,
          100,
        ),
        discount: CalculatorService.divide(
          this.issueCreditNoteInput.invoiceData.discount,
          100,
        ),
        promotions: CalculatorService.divide(
          this.issueCreditNoteInput.invoiceData.creditsApplied,
          100,
        ),
        tax: CalculatorService.divide(
          this.issueCreditNoteInput.invoiceData.tax,
          100,
        ),
        total: CalculatorService.divide(
          this.issueCreditNoteInput.invoiceData.total,
          100,
        ),
      };
    else if (this.proratedCreditNote) {
      this.invoiceData = {
        subtotal: 0,
        discount: 0,
        promotions: 0,
        tax: 0,
        total: 0,
      };
      if (this.proratedCreditNote.refundable) {
        const {
          sub_total: subTotal,
          discounts,
          taxes,
          total,
        } = this.proratedCreditNote.refundable;
        const discount = CalculatorService.divide(
          discounts ? discounts.reduce((acc, curr) => acc + curr.amount, 0) : 0,
          100,
        );
        this.invoiceData = {
          subtotal: CalculatorService.divide(subTotal, 100) + discount,
          discount,
          promotions: CalculatorService.divide(
            this.issueCreditNoteInput.invoiceData.creditsApplied,
            100,
          ),
          tax: CalculatorService.divide(
            taxes ? taxes.reduce((acc, curr) => acc + curr.amount, 0) : 0,
            100,
          ),
          total: CalculatorService.divide(total, 100),
        };
      }
      if (this.proratedCreditNote.adjustment) {
        const { total } = this.proratedCreditNote.adjustment;
        this.invoiceData = {
          ...this.invoiceData,
          total: this.invoiceData.total + CalculatorService.divide(total, 100),
        };
      }
    }
  }

  routeToAccountList(planId?: string): void {
    const { email, subscriptionId } = this.issueCreditNoteInput;
    this.router.navigate(['/accounts/list'], {
      state: {
        email,
        subscriptionId,
        popupHeadingMsg: 'Credit Note Issued Successfully',
        popupMsg: 'You can see the credit note in the billing history',
        planWarning: !!planId,
      },
    });
  }

  handleSelectChange(event: SelectButtonChangeEvent): void {
    const selectedValue = event.value;
    this.formGroup.controls.refundValue.setValue(selectedValue);

    const refundTypeControl = this.formGroup.get('refundType');
    if (this.refundValue === RefundValue.Full && this.isPartial) {
      refundTypeControl?.setValidators(Validators.required);
    } else {
      refundTypeControl?.setValidators(null);
    }

    refundTypeControl?.updateValueAndValidity();
  }

  close(popupData?: PopupData): void {
    this.formGroup.reset();
    this.formGroup.controls.refundValue.setValue('full');
    this.proratedCreditNote = null;
    if (popupData) this.closeDialog.emit(popupData);
    else this.closeDialog.emit();
  }

  updateValues(): void {
    this.setProductList();
    this.setInvoiceData();
  }

  getRefundNoteValue(): string {
    return this.formGroup.get('notes')?.value || '';
  }

  public get refundValue(): string {
    return this.formGroup.get('refundValue')?.value;
  }

  public get notes(): AbstractControl {
    return this.formGroup.get('notes');
  }

  public get refundReason(): string {
    return this.formGroup.get('refundReason')?.value;
  }

  public get refundType(): string {
    return this.formGroup.get('refundType')?.value;
  }

  public get selectedRefundTypeValue(): string {
    if (!this.isPartial) return this.formGroup.get('refundValue')?.value;
    const selectedItem = this.refundType;
    if (!selectedItem) return '';
    return selectedItem.toLowerCase();
  }

  public get getCreditNoteAmountByType(): number {
    const selectedItem = this.refundType;

    if (!this.isPartial) return this.invoiceData.total;

    if (!selectedItem) return 0;
    if (selectedItem === CreditNoteTypes.Adjustment)
      return this.issueCreditNoteInput.invoiceData.dueAmount / 100;
    if (selectedItem === CreditNoteTypes.Refundable)
      return this.issueCreditNoteInput.invoiceData.amountPaid / 100;

    return 0;
  }

  public get creditNoteSubtitle(): { amount: number; text: string }[] {
    const { dueAmount, amountPaid } = this.issueCreditNoteInput.invoiceData;
    if (this.isPartial) {
      if (this.refundValue === RefundValue.Full) {
        const amount =
          this.refundType === CreditNoteTypes.Adjustment
            ? dueAmount / 100
            : amountPaid / 100;

        return [
          {
            amount,
            text: `${this.refundValue} ${this.refundType ? this.refundType.toLowerCase() : ''} credit`,
          },
        ];
      }
      return [
        {
          amount: this.proratedCreditNote.refundable
            ? this.proratedCreditNote.refundable.total / 100
            : 0,
          text: `${this.refundValue} refundable credit`,
        },
        {
          amount: this.adjustmentCreditNoteAmount,
          text: `${this.refundValue} adjustment credit`,
        },
      ];
    }

    // for full paid invoices
    if (this.refundValue === RefundValue.Full)
      return [
        {
          amount: this.invoiceData.total,
          text: `${this.refundValue} refund amount`,
        },
      ];

    return [
      {
        amount: this.proratedCreditNote.refundable
          ? this.proratedCreditNote.refundable.total / 100
          : 0,
        text: `${this.refundValue} refundable credit`,
      },
    ];
  }

  get isCharges(): boolean {
    return this.issueCreditNoteInput.invoiceData.desc === 'Charges';
  }

  get adjustmentCreditNoteAmount(): number {
    if (!this.proratedCreditNote || !this.proratedCreditNote.adjustment)
      return 0;
    return this.proratedCreditNote.adjustment.total / 100;
  }

  onSuccess(
    plan: BillingDocumentLineItem,
    response: CreateCreditNoteResponseDTO,
  ): void {
    if (
      this.issueCreditNoteInput.flowType ===
      IssueCreditNoteFlowType.FROM_INVOICE_TABLE
    ) {
      const popupData: PopupData = {
        popupHeadingMsg: 'Credit Note Issued Successfully',
        popupMsg: 'You can see the credit note in the billing history',
        planWarning: !!plan?.id,
        email: this.issueCreditNoteInput.email,
        creditNoteId: response.creditNoteId,
      };
      this.close(popupData);
      return;
    }
    this.close();
    this.routeToAccountList(plan?.id);
  }
}
