<div class="overlay">
  <div>
    <h1>{{ index }} / {{ invoices.length }} ({{ idList.length }})</h1>
  </div>
  <div>
    <h3>{{ (index * 100) / invoices.length | number: '1.2-2' }}%</h3>
  </div>
  <div>
    {{ invoice?.id }}
  </div>
  <div
    *ngIf="invoicesWithErrors.length > 0"
    class=""
  >
    <p-button (click)="copyErrorIds()">Copy IDs</p-button>
    <br />
    <b>Errors:</b>

    <div class="error-ids">
      @for (err of invoicesWithErrors; track err) {
        <div>{{ err.id }} : {{ err.error }}</div>
      }
    </div>
  </div>
</div>

@if (invoice) {
  <rwa-invoice-print
    [invoice]="invoice"
    [isCreditNote]="invoice.type === 'credit_note'"
    [isBulkOperation]="isBulkOperation"
  ></rwa-invoice-print>
}
<!-- @for (inv of invoices; track $index) {
  <rwa-invoice-print
    [invoice]="inv"
    [isCreditNote]="inv.type === 'credit_note'"
    [isBulkOperation]="isBulkOperation"
  ></rwa-invoice-print>
} -->
