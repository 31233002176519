// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import { FormControl, ValidatorFn } from '@angular/forms';

export const convertToTitleCase = (name: string) => {
  const string = name.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatDate = (dateString: string | undefined): string => {
  const placeholderResponse = '-';
  try {
    if (!dateString) return placeholderResponse;

    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      calendar: 'gregory',
      dateStyle: 'medium',
    });
    return formattedDate ?? placeholderResponse;
  } catch (error: unknown) {
    return placeholderResponse;
  }
};

export const getPhoneNumberValidator =
  (phoneSelectedCountry: any): ValidatorFn =>
  (control: FormControl): null | Record<string, boolean> => {
    const inputValue = control.value;

    if (phoneSelectedCountry?.countryKey !== 'SA') {
      return /^[0-9]{5,13}$/.test(inputValue)
        ? null
        : { otherThanSaudiPattern: true };
    }

    if (!inputValue.startsWith('05') && !inputValue.startsWith('5')) {
      return { saudiZeroOrZeroFive: true };
    }

    const saudiPattern = inputValue.startsWith('0')
      ? /^05[0-9]{8}$/
      : /^5[0-9]{8}$/;

    const saudiError = inputValue.startsWith('0')
      ? { zeroSaudiPattern: true }
      : { saudiPattern: true };

    return saudiPattern.test(inputValue) ? null : saudiError;
  };
