import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  AdminOnlinePaymentDTO,
  ChargesType,
  TrainingInfo,
} from '@rewaa-team/types';
import {
  AccountDetailsDTO,
  AccountExistsResponse,
  AttachedItemDTO,
  CheckoutEstimateReqDTO,
  EstimateV2Response,
  PaginatedAccountListDTO,
  PlanAttachmentsResponse,
  RegisterNewMerchantDTO,
  UpdateMerchantResponseDTO,
  EstimateResponseLicenseKey,
  ShippingAddress,
  CheckoutRenewEstimateReqDTO,
  PaymentProofDTO,
  PartialPaymentDTO,
  CreditNoteRequest,
  BillingHistoryResponseDTO,
  RetryAndResubmissionPayload,
  CreateCreditNoteResponseDTO,
  CreditNoteRequestForPartiallyPaidInvoice,
  Country,
} from './types';

const API_URL = `/api/nucleus`;

@Injectable()
export class AccountService {
  constructor(private http: HttpClient) {}

  getAccounts(
    page: number,
    limit: number,
    searchParam?: string,
  ): Observable<PaginatedAccountListDTO> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());

    if (searchParam) {
      params = params.set('searchParam', searchParam);
    }

    return this.http.get<PaginatedAccountListDTO>(`${API_URL}/admin/accounts`, {
      params,
    });
  }

  getAccount(email: string): Observable<AccountDetailsDTO> {
    return this.http.get<AccountDetailsDTO>(
      `${API_URL}/admin/account-details/${email}`,
    );
  }

  issueCreditNote(
    payload: CreditNoteRequest,
  ): Observable<CreateCreditNoteResponseDTO> {
    return this.http.post<CreateCreditNoteResponseDTO>(
      `${API_URL}/admin/credit-note`,
      payload,
    );
  }

  issueCreditNoteForPartiallyPaidInvoice(
    payload: CreditNoteRequestForPartiallyPaidInvoice,
  ): Observable<CreateCreditNoteResponseDTO> {
    return this.http.post<CreateCreditNoteResponseDTO>(
      `${API_URL}/admin/credit-note/partially-paid-invoice`,
      payload,
    );
  }

  resetAccount(
    email: string,
    payload: {
      recordId: string;
      url: string;
      titleMeta: string;
    },
  ): Observable<void> {
    return this.http.post<void>(
      `${API_URL}/admin/merchant-info/initiate-reset-data`,
      {
        email,
        zohoDetails: payload,
      },
    );
  }

  getBillingHistoryBySubscriptionId(
    subscriptionId: string,
  ): Observable<BillingHistoryResponseDTO> {
    return this.http.get<BillingHistoryResponseDTO>(
      `${API_URL}/admin/invoices/billing-history/${subscriptionId}`,
    );
  }

  getSubscriptionStatusBySubscriptionId(
    subscriptionId: string,
  ): Observable<boolean> {
    const params = new HttpParams().set('subscriptionId', subscriptionId);
    return this.http.get<boolean>(`${API_URL}/subscription/status`, { params });
  }

  getAllActivePlans(): Observable<AttachedItemDTO[]> {
    return this.http.get<AttachedItemDTO[]>(`${API_URL}/admin/plans`);
  }

  getPlanAttachmentsByPlanId(
    planItemId: string,
    chargesType: ChargesType = ChargesType.Hardware,
  ): Observable<PlanAttachmentsResponse> {
    const params = new HttpParams().set('chargesType', chargesType);
    return this.http.get<PlanAttachmentsResponse>(
      `${API_URL}/admin/plans/${planItemId}/attached-items`,
      {
        params,
      },
    );
  }

  getSubscriptionStatus(
    subscriptionId: string,
  ): Observable<{ subscriptionStatus: string | undefined }> {
    return this.http.get<{ subscriptionStatus: string | undefined }>(
      `${API_URL}/admin/subscription-status/${subscriptionId}`,
    );
  }

  getEstimate(
    estimateReq: CheckoutEstimateReqDTO,
  ): Observable<EstimateV2Response> {
    return this.http.post<EstimateV2Response>(
      `${API_URL}/admin/checkout/estimate`,
      estimateReq,
    );
  }

  getRenewEstimate(
    estimateReq: CheckoutRenewEstimateReqDTO,
  ): Observable<EstimateV2Response> {
    return this.http.post<EstimateV2Response>(
      `${API_URL}/admin/checkout/renew/estimate`,
      estimateReq,
    );
  }

  updateAdminSubscription(payload: AdminOnlinePaymentDTO): Observable<void> {
    const isPartial = payload.isPartial ? '?ispartial=true' : '';
    return this.http.put<void>(
      `${API_URL}/admin/subscription${isPartial}`,
      payload,
    );
  }

  getAccountInfo(email: string): Observable<AccountExistsResponse> {
    return this.http.get<AccountExistsResponse>(
      `${API_URL}/admin/merchant-info/${email}`,
    );
  }

  getMerchantAndCRMInfo(accountId: string): Observable<any> {
    return this.http.get<any>(
      `${API_URL}/admin/merchant-crm-info/${accountId}`,
    );
  }

  registerNewMerchant(payload: RegisterNewMerchantDTO): Observable<{
    success: boolean;
    hasCRMAccount?: boolean;
    subscriptionID?: string;
  }> {
    return this.http.post<{ success: boolean }>(
      `${API_URL}/admin/merchant-info`,
      payload,
    );
  }

  updateMerchantInfo(
    email: string,
    payload: any,
  ): Observable<UpdateMerchantResponseDTO> {
    return this.http.put<UpdateMerchantResponseDTO>(
      `${API_URL}/admin/merchant-info/${email}`,
      payload,
    );
  }

  getLicenseKeyEstimateItems(
    subscriptionId: string,
    licenseKey: string,
  ): Observable<EstimateResponseLicenseKey> {
    return this.http.get<EstimateResponseLicenseKey>(
      `${API_URL}/admin/license-key/${licenseKey}/estimate/${subscriptionId}`,
    );
  }

  updateLicenseKeySubscription(
    subscriptionId: string,
    licenseKey: string,
    shippingDetails: ShippingAddress | undefined,
    crmAccountDetails: TrainingInfo,
  ): Observable<{ id: string; quantity: number }[] | undefined> {
    return this.http.put<{ id: string; quantity: number }[] | undefined>(
      `${API_URL}/admin/license-key/activate`,
      {
        id: subscriptionId,
        licenseKey,
        shippingAddress: shippingDetails,
        crmAccountDetails,
      },
    );
  }

  uploadPaymentReceipt(
    base64: string,
    email: string,
  ): Observable<{ url: string }> {
    return this.http.post<{ url: string }>(`${API_URL}/admin/payment-receipt`, {
      base64,
      email,
    });
  }

  getPaymentProofs(
    chargebeeInvoiceId: string,
    subscriptionId: string,
  ): Observable<PaymentProofDTO> {
    return this.http.get<PaymentProofDTO>(
      `${API_URL}/admin/invoice/payment-proofs?chargebeeInvoiceId=${chargebeeInvoiceId}&subscriptionId=${subscriptionId}`,
    );
  }

  updateCRMEmailInfo(
    accountID: string,
    body: { email: string },
  ): Observable<{ success: boolean }> {
    return this.http.put<{ success: boolean }>(
      `${API_URL}/admin/crm-info/${accountID}`,
      body,
    );
  }

  postPartialPayment(
    body: PartialPaymentDTO,
    invoiceId: string,
  ): Observable<void> {
    return this.http.post<void>(
      `${API_URL}/admin/partial-payment/${invoiceId}`,
      body,
    );
  }

  // checkCRMAccountAvaliabilty
  checkCRMAccountAvaliabilty(email: string): Observable<void> {
    return this.http.get<void>(`${API_URL}/admin/check-crm-account/${email}`);
  }

  updateInvoiceBilling(data: RetryAndResubmissionPayload): Observable<void> {
    return this.http.post<void>(
      `${API_URL}/admin/invoice/billing/${data.id}`,
      data,
    );
  }

  getPaymentMethod(): Observable<string> {
    return this.http.get<string>(`${API_URL}/admin/payment-types`);
  }

  retryInvoicesOrCreditNotes(
    data: RetryAndResubmissionPayload,
  ): Observable<void> {
    const { id, type } = data;

    if (type === 'invoice') {
      return this.http.post<void>(
        `${API_URL}/zatca-invoicing/invoice/${id}`,
        data,
      );
    }

    return this.http.post<void>(
      `${API_URL}/zatca-invoicing/credit-note/${id}`,
      data,
    );
  }

  getCountries(): Observable<Country[]> {
    return this.http
      .get<Country[]>(`/api/lookups/countries`)
      .pipe(map((response) => response.map((country) => new Country(country))));
  }

  getProratedCreditNoteEstimate(data: {
    subscriptionId: string;
    invoiceId: string;
  }): Observable<any[]> {
    return this.http.post<any[]>(
      `${API_URL}/admin/credit-note/prorated-estimate`,
      data,
    );
  }

  generatePartialProratedCreditNote(
    data: CreditNoteRequest,
  ): Observable<CreateCreditNoteResponseDTO> {
    return this.http.post<CreateCreditNoteResponseDTO>(
      `${API_URL}/admin/prorated-credit-note/partially-paid-invoice`,
      data,
    );
  }
}
