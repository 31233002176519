import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ZATCAWarningsAndErrors } from '../../../../invoices/types';
import { ZatcaMessage } from '../../../types';

@Component({
  selector: 'rwa-zatca-warning',
  templateUrl: './zatca-warning.component.html',
  styleUrls: ['./zatca-warning.component.scss'],
})
export class ZatcaWarningComponent {
  @Input() dialogVisible: boolean;

  @Input({ required: true }) warningsAndErrors: ZATCAWarningsAndErrors;

  @Output() closeDialog = new EventEmitter<void>();

  close(): void {
    this.closeDialog.emit();
  }

  copyText = {
    warnings: 'Copy',
    errors: 'Copy',
    nucleusEnigma: 'Copy',
  };

  handleCopy(
    content: 'warnings' | 'errors' | 'nucleusEnigma',
    event: MouseEvent,
  ): void {
    event.stopPropagation();
    if (content === 'warnings') {
      this.copy(this.warningsAndErrors.warnings);
      this.updateCopyText('warnings');
    } else if (content === 'errors') {
      this.copy(this.warningsAndErrors.errors);
      this.updateCopyText('errors');
    } else if (content === 'nucleusEnigma') {
      this.copy(this.warningsAndErrors.nucleusEnigma);
      this.updateCopyText('nucleusEnigma');
    }
  }

  copy(obj: ZatcaMessage[] | object): void {
    const textArea = document.createElement('textarea');
    textArea.value = JSON.stringify(obj);
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }

  updateCopyText(content: 'warnings' | 'errors' | 'nucleusEnigma'): void {
    this.copyText[content] = 'Copied!';
    setTimeout(() => {
      this.copyText[content] = 'Copy';
    }, 2000);
  }
}
