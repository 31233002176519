@if (
  warningsAndErrors?.errors?.length > 0 ||
  warningsAndErrors?.warnings?.length > 0 ||
  warningsAndErrors?.nucleusEnigma
) {
  <p-dialog
    [visible]="
      warningsAndErrors.errors.length > 0 ||
      warningsAndErrors.warnings.length > 0 ||
      !!warningsAndErrors.nucleusEnigma
    "
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [closable]="false"
    [style]="{ width: '100%', maxWidth: '624px' }"
    (onClose)="close()"
  >
    <p-header class="flex justify-content-between align-items-center">
      <div>
        <div class="flex gap-4">
          <rwa-tag
            *ngIf="warningsAndErrors.errors.length > 0"
            type="red"
            text="{{ warningsAndErrors.errors.length + ' ZATCA Errors' }}"
          ></rwa-tag>
          <rwa-tag
            *ngIf="!!warningsAndErrors.nucleusEnigma"
            type="red"
            text="
            {{
              warningsAndErrors.nucleusEnigma ? '1' : '0'
            }} Nucleus/Enigma Error"
          ></rwa-tag>
          <rwa-tag
            *ngIf="warningsAndErrors.warnings.length > 0"
            type="yellow"
            text="
            {{ warningsAndErrors.warnings.length + ' ZATCA Warnings' }}"
          ></rwa-tag>
        </div>
      </div>
      <p-button
        (click)="close()"
        [outlined]="true"
      >
        Close
      </p-button>
    </p-header>
    <p-accordion [multiple]="true">
      <p-accordionTab
        *ngIf="warningsAndErrors.errors.length > 0"
        header="{{ warningsAndErrors.errors.length + ' ZATCA  Errors' }}"
        [selected]="warningsAndErrors.errors.length > 0"
      >
        <ng-template pTemplate="header">
          <p-button
            id="errors-copy"
            class="ml-auto"
            (click)="handleCopy('errors', $event)"
            >{{ copyText.errors }}</p-button
          >
        </ng-template>
        @for (error of warningsAndErrors.errors; track $index) {
          <p><b>Type:</b> {{ error.type }}</p>
          <p><b>Status:</b> {{ error.status }}</p>
          <p><b>Category:</b> {{ error.category }}</p>
          <p><b>Message:</b> {{ error.message }}</p>
          <hr />
        }
      </p-accordionTab>
      <p-accordionTab
        *ngIf="!!warningsAndErrors.nucleusEnigma"
        header="{{ ' Nucleus/Enigma  Errors' }}"
        [selected]="!!warningsAndErrors.nucleusEnigma"
      >
        <ng-template pTemplate="header">
          <p-button
            id="nucleusEnigma-copy"
            class="ml-auto"
            (click)="handleCopy('nucleusEnigma', $event)"
          >
            {{ copyText.nucleusEnigma }}</p-button
          >
        </ng-template>
        @if (warningsAndErrors.nucleusEnigma.message) {
          <p><b>Message:</b> {{ warningsAndErrors.nucleusEnigma.message }}</p>
        }
        <pre>{{ warningsAndErrors.nucleusEnigma | json }}</pre>
      </p-accordionTab>
      <p-accordionTab
        *ngIf="warningsAndErrors.warnings.length > 0"
        header="{{ warningsAndErrors.warnings.length + ' ZATCA  Warnings' }}"
        [selected]="warningsAndErrors.warnings.length > 0"
      >
        <!-- Accordion header with a button to copy content -->
        <ng-template pTemplate="header">
          <p-button
            id="warnings-copy"
            class="ml-auto"
            (click)="handleCopy('warnings', $event)"
            >{{ copyText.warnings }}</p-button
          >
        </ng-template>
        <div id="warning">
          @for (warning of warningsAndErrors.warnings; track $index) {
            <p><b>Code:</b> {{ warning.code }}</p>
            <p><b>Type:</b> {{ warning.type }}</p>
            <p><b>Status:</b> {{ warning.status }}</p>
            <p><b>Category:</b> {{ warning.category }}</p>
            <p><b>Message:</b> {{ warning.message }}</p>
            <hr />
          }
        </div>
      </p-accordionTab>
    </p-accordion>
  </p-dialog>
}
