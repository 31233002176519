import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ChargebeeZatcaInvoicesResponseDTO,
  ChargebeeZatcaReturnInvoicesResponseDTO,
  MappedBillingDocumentWithPaymentReceiptData,
} from './types';

const API_URL = `/api/nucleus`;

@Injectable()
export class InvoicesService {
  constructor(private http: HttpClient) {}

  getInvoices(
    page: number,
    limit: number,
    sortAsc: boolean,
    searchParam?: string,
    filters?: Record<string, any>,
  ): Observable<ChargebeeZatcaInvoicesResponseDTO> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());

    params = params.set('sortAsc', sortAsc);

    if (searchParam) {
      params = params.set('searchParam', searchParam);
    }

    if (filters) {
      params = params.set('filters', JSON.stringify(filters));
    }

    return this.http.get<ChargebeeZatcaInvoicesResponseDTO>(
      `${API_URL}/admin/invoices`,
      {
        params,
      },
    );
  }

  getInvoiceById(
    invoiceId: string,
  ): Observable<MappedBillingDocumentWithPaymentReceiptData> {
    return this.http.get<MappedBillingDocumentWithPaymentReceiptData>(
      `${API_URL}/admin/invoice/${invoiceId}`,
    );
  }

  getCreditNotes(
    page: number,
    limit: number,
    sortAsc: boolean,
    searchParam?: string,
    filters?: Record<string, any>,
  ): Observable<ChargebeeZatcaReturnInvoicesResponseDTO> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());

    params = params.set('sortAsc', sortAsc);

    if (searchParam) {
      params = params.set('searchParam', searchParam);
    }

    if (filters) {
      params = params.set('filters', JSON.stringify(filters));
    }

    return this.http.get<ChargebeeZatcaReturnInvoicesResponseDTO>(
      `${API_URL}/admin/return-invoice`,
      {
        params,
      },
    );
  }

  getCreditNoteById(
    creditNoteId: string,
  ): Observable<MappedBillingDocumentWithPaymentReceiptData> {
    return this.http.get<MappedBillingDocumentWithPaymentReceiptData>(
      `${API_URL}/admin/credit-note/${creditNoteId}`,
    );
  }
}
