<form [formGroup]="customerForm" #ngForm class="flex flex-column">
  <div class="flex flex-column gap-4">
    <div class="flex flex-column gap-2">
      <label class="font-normal text-xl">{{ translationKey + 'confirmTheBillingDetails' | translate }}</label>
      <span class="font-normal text-sm text-neutral-70">{{ 'Please enter the customer’s information accurately.' | translate }}</span>
    </div>
    <div class="flex flex-column gap-3">
      <div class="flex flex-column gap-3">
        <label class="font-normal text-xl">{{ translationKey + 'clientAccountInformation' | translate }}</label>
        <div class="grid">
          <!-- <label class="font-medium text-base">{{ 'Customer’s VAT Number' | translate }}</label> -->
          <div class="md:col-6">
            <div class="flex flex-column gap-3">
              <div class="flex flex-column gap-1">
                <div class="p-float-label w-full"
                  [ngClass]="{'showRedAsterisk': customerForm.get('vatNumber')?.enabled, 'showGreyAsterisk': !customerForm.get('vatNumber')?.enabled}">
                  <input
                    pInputText
                    formControlName="vatNumber"
                    type="text"
                    id="vatNumber"
                    placeholder=" "
                    class="w-full"
                    required
                  />
                  <label for="vatNumber">{{ 'Customer VAT Number' | translate }}</label>
                </div>
                <span class="text-neutral-70 text-xs">15 digits, starting and ending with 3, Ex: 301121971500003</span>
                @if (vatNumber?.errors && (vatNumber?.dirty || vatNumber?.touched)) {
                  @if (vatNumber?.errors?.required) {
                    <span class="text-red-60 text-xs">
                      {{ translationKey + 'vatNumberRequired' | translate }}
                    </span>
                  }
                  @if (vatNumber?.errors?.pattern) {
                    <span class="text-red-60 text-xs">
                      VAT number should numerical only
                    </span>
                  }
                  @if (vatNumber?.errors?.maxlength) {
                    <span class="text-red-60 text-xs">
                      VAT number should be 15 characters
                    </span>
                  }
                }
                <div class="flex align-items-center gap-2">
                <input
                  (ngModelChange)="handleVATToggle()"
                  type="checkbox"
                  id="noVat"
                  name="noVat"
                  class="m-0"
                  [checked]="customerForm.get('nonTaxableEntity')?.value"
                  formControlName="nonTaxableEntity"
                />
                <label for="noVat" class="font-normal text-sm">
                  {{ translationKey + 'customerDoesntHaveVAT' | translate }}
                </label>
              </div>
              </div>
            </div>
          </div>
          <div class="md:col-6">
            <div class="flex flex-column gap-3">
              <div class="flex flex-column gap-1">
                <span class="p-float-label showAsterisk w-full">
                  <input
                    pInputText
                    type="text"
                    id="companyName"
                    name="companyName"
                    formControlName="companyName"
                    placeholder=" "
                    class="w-full"
                    required
                  />
                  <label>Company Name</label>
                </span>
                <span class="text-neutral-70 text-xs align-right-below">اسم العميل ( شركة / مؤسسة / شهادة العمل الحر )</span>
                @if (
                companyName?.errors && (companyName?.dirty || companyName?.touched)
                ) {
                  @if (companyName?.errors?.required) {
                    <span class="text-red-60 text-xs">
                    {{
                      translationKey + 'companyNameIsRequired' | translate
                    }}
                    </span>
                  }
                  @if ( companyName?.errors?.maxlength) {
                    <span class="text-red-60 text-xs">
                      Company name should be 149 characters
                    </span>
                  }
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      @if(!isPhoneNumberAvailable){
      <div class="flex gap-2 align-items-center">
        <!-- Country Code Dropdown -->
        <span class="rw__input rw__input-float p-float-label minw-0 w-9rem">
          <p-dropdown panelStyleClass="rw__input-country-dropdown"
            [options]="countriesWithData"
            [(ngModel)]="phoneSelectedCountry"
            optionLabel="name"
            [filter]="true"
            filterBy="localeName"
            [autoDisplayFirst]="false"
            (onChange)="onCountrySelect()"
            [ngModelOptions]="{standalone: true}"
            appendTo="body">
            <ng-template pTemplate="selectedItem">
              <div class="flex align-items-center right-pad">
                <img class="border-round" src="https://flagsapi.com/{{phoneSelectedCountry.countryKey}}/flat/32.png"
                  alt="{{ phoneSelectedCountry.name }} Flag" width="22" />
              </div>
            </ng-template>
            <ng-template let-country pTemplate="item">
              <div class="d-flex align-items-center">
                <span>{{ country.localeName }}</span>
                <span class="rw__ms-2 ltr">(+{{country.code}})</span>
              </div>
            </ng-template>
          </p-dropdown>
        </span>

        <!-- Phone Number Input -->
        <span class="rw__input rw__input-default minw-0 w-full mar-top">
          <div class="phone-input-wrapper showAsterisk">
            <span class="phone-code">+{{ phoneSelectedCountry.code }}</span>
            <input name="phone" type="text" pInputText formControlName="phone" style="padding-left: 2px;"
              autocomplete="one-time-code" placeholder="XX XXX XXXX" />
          </div>
        </span>
        </div>

        @if (phone?.errors && (phone?.dirty || phone?.touched)) {
          @if (phone?.errors?.required) {
            <span class="text-red-60 text-xs">
            * {{
              translationKey + 'phoneIsRequired' | translate
            }}
          </span>
          }
          @if ( phone?.errors?.zeroSaudiPattern) {
            <span class="text-red-60 text-xs">
              * {{
              translationKey + 'zeroSaudiPattern' | translate
            }}
            </span>
          }
          @if ( phone?.errors?.saudiPattern) {
            <span class="text-red-60 text-xs">
             * {{
              translationKey + 'saudiPattern' | translate
            }}
            </span>
          }
          @if ( phone?.errors?.saudiZeroOrZeroFive) {
            <span class="text-red-60 text-xs">
              * {{
              translationKey + 'saudiZeroOrZeroFive' | translate
            }}
            </span>
          }
          @if ( phone?.errors?.otherThanSaudiPattern) {
            <span class="text-red-60 text-xs">
              * {{
              translationKey + 'otherThanSaudiPattern' | translate
            }}
            </span>
          }
        }
      }

      <div class="grid">

      <div class="md:col-6">
        <div class="flex flex-column gap-1">
          <span class="p-float-label showAsterisk w-full">
            <p-dropdown
                [options]="additionalIdentifiers"
                formControlName="additionalIdentifier"
                [filter]="true"
                placeholder="Select Additional Identifier"
                styleClass="w-full"
                [required]="true"
                ngDefaultControl
            >
              <ng-template pTemplate="selectedItem" let-selectedOption>
                  <div class="flex align-items-center gap-2">
                      <div class="w-full">{{ selectedOption }}</div>
                  </div>
              </ng-template>
              <ng-template let-option pTemplate="item">
                  <div class="flex align-items-center gap-2">
                      <div class="w-full">{{ option }}</div>
                  </div>
              </ng-template>
          </p-dropdown>
            <label class="dropdown-label">Select Additional Identifier</label>
          </span>
          @if (
            additionalIdentifier?.errors && (additionalIdentifier?.dirty || additionalIdentifier?.touched)
            ) {
              @if (additionalIdentifier?.errors?.required) {
                 <span class="text-red-60 text-xs">
                  {{
                    translationKey + 'additionalIdentifierIsRequired' | translate
                  }}
                  </span>
                }
              }
        </div>
      </div>
      </div>

      <div class="grid">

      <div class="md:col-6">
        <div class="flex flex-column gap-3">
          <div class="flex flex-column gap-1">
            <span class="p-float-label w-full"
            [ngClass]="{'showRedAsterisk': customerForm.get('additionalIdentifierNumber')?.enabled, 'showGreyAsterisk': !customerForm.get('additionalIdentifierNumber')?.enabled}"
            >
              <input
                pInputText
                type="text"
                id="additionalIdentifierNumber"
                name="additionalIdentifierNumber"
                formControlName="additionalIdentifierNumber"
                placeholder=""
                class="w-full"
                required
              />
              <label>Additional Identifier Number </label>
            </span>
            <span class="text-neutral-70 text-xs">10 digits exact</span>
            @if (additionalIdentifierNumber?.errors && (additionalIdentifierNumber?.dirty || additionalIdentifierNumber?.touched)) {
              @if (additionalIdentifierNumber?.errors?.required) {
                <span class="text-red-60 text-xs">
              {{
                translationKey + 'additionalIdentifierNumberRequired' | translate
              }}
            </span>
            }@if (additionalIdentifierNumber?.errors?.maxlength || additionalIdentifierNumber?.errors?.minlength) {
              <span
                class="text-red-60 text-xs"
              >
                <small>Input must be exactly 10 characters long.</small>
              </span>
            }
            @if (additionalIdentifierNumber?.errors?.pattern) {
              <span class="text-red-60 text-xs">
                Additional identifier number should numerical only
              </span>
            }

            }
          </div>
        </div>
      </div>
      </div>

      <hr class="border-neutral-20 border-top-1 border-bottom-none border-x-0 m-0">

      <div class="flex flex-column gap-3">
        <label class="font-medium text-base">{{ 'Company Address' | translate }}</label>
        <div class="grid">
          <div class="md:col-6 pt-3 pb-2">
            <div class="flex flex-column gap-1">
              <span class="p-float-label showAsterisk w-full">
                <p-dropdown
                [options]="countries"
                [(ngModel)]="selectedCountry"
                (ngModelChange)="updateAvailableCities()"
                formControlName="country"
                optionLabel="name"
                [filter]="true"
                filterBy="name"
                placeholder="Country"
                styleClass="w-full"
                [required]="true"
                ngDefaultControl
              >
                <ng-template
                  pTemplate="selectedItem"
                  let-selectedOption
                  class="w-full"
                >
                  <div
                    class="flex align-items-center gap-2"
                    class="w-full"
                  >
                    <div class="w-full">{{ selectedOption.name }}</div>
                  </div>
                </ng-template>
                <ng-template
                  let-country
                  pTemplate="item"
                  class="w-full"
                >
                  <div
                    class="flex align-items-center gap-2"
                    class="w-full"
                  >
                    <div class="w-full">{{ country.name }}</div>
                  </div>
                </ng-template>
                </p-dropdown>
                <label class="dropdown-label">Country</label>
              </span>
            </div>
          </div>
          <div class="md:col-6 pt-3 pb-2">
            <div class="flex flex-column gap-1">
              <span class="p-float-label showAsterisk w-full">
                <p-autoComplete
                [dropdown]="true"
                formControlName="city"
                [suggestions]="filteredCities"
                (completeMethod)="filterCities($event)"
                optionLabel="name"
                filterBy="name"
                placeholder=" "
                styleClass="w-full"
                [required]="true"
                ngDefaultControl
              >
                <ng-template
                  pTemplate="selectedItem"
                  let-selectedOption
                  class="w-full"
                >
                  <div
                    class="flex align-items-center gap-2"
                    class="w-full"
                  >
                    <div class="w-full">{{ selectedOption }}</div>
                  </div>
                </ng-template>
                <ng-template
                  let-country
                  pTemplate="item"
                  class="w-full"
                >
                  <div
                    class="flex align-items-center gap-2"
                    class="w-full"
                  >
                    <div class="w-full">{{ country.name }}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
              <label class="dropdown-label">City</label>
              @if (city?.errors && (city?.dirty || city?.touched)) {
                @if (city?.errors?.required) {
                  <span
                    class="text-red-60 text-xs"
                  >
                    <small>{{
                     translationKey + 'cityNameRequired' | translate
                    }}</small>
                  </span>
                }
                @else if (city?.errors?.invalidCity) {
                  <span
                    class="text-red-60 text-xs"
                  >
                    <small>{{
                     translationKey + 'invalidCity' | translate
                    }}</small>
                  </span>
                }
              }
              </span>
            </div>
          </div>
          <div *ngIf="showCustomCityNameField" class="md:col-6 pt-3 pb-2">
            <div class="flex flex-column gap-1">
              <span class="p-float-label showAsterisk w-full">
                <input
                  pInputText
                  type="text"
                  formControlName="customCityName"
                  placeholder=" "
                  required
                  class="w-full"
                />
                <label>Add City Name</label>
              </span>
              <div *ngIf="customCityName?.errors && (customCityName?.dirty || customCityName?.touched)" class="text-red-60 text-xs">
                <span *ngIf="customCityName?.errors?.required">
                  <small>{{ translationKey + 'cityNameRequired' | translate }}</small>
                </span>
                <span *ngIf="customCityName?.errors?.maxlength">
                  <small>City name should not be more than 49 characters</small>
                </span>
              </div>
            </div>
          </div>

          <div class="md:col-6 pt-3 pb-2">
            <div class="flex flex-column gap-1">
              <span class="p-float-label showAsterisk w-full">
                <input
                  pInputText
                  type="text"
                  id="districtName"
                  name="districtName"
                  formControlName="districtName"
                  required
                  placeholder=" "
                  class="w-full"
                />
                <label>District Name</label>
              </span>
              <div *ngIf="districtName?.errors && (districtName?.dirty || districtName?.touched)" class="text-red-60 text-xs">
                <span *ngIf="districtName?.errors?.required">
                  <small>{{ translationKey + 'districtNameRequired' | translate }}</small>
                </span>
                <span *ngIf="districtName?.errors?.maxlength">
                  <small>Input must not exceed 150 characters.</small>
                </span>
              </div>
            </div>
          </div>
          <div class="md:col-6 pt-3 pb-2">
            <div class="flex flex-column gap-1">
              <span class="p-float-label showAsterisk w-full">
                <input
                  pInputText
                  type="text"
                  id="streetName"
                  name="streetName"
                  formControlName="streetName"
                  class="w-full"
                  placeholder=" "
                  required

                />
                <label>Street Name</label>
              </span>
              @if (streetName?.errors && (streetName?.dirty || streetName?.touched)) {
                @if (streetName?.errors?.required) {
                  <span class="text-red-60 text-xs">
                {{
                  translationKey + 'streetNameRequired' | translate
                }}
              </span>
            }
            @if (streetName?.errors?.maxlength) {
              <span
                class="text-red-60 text-xs"
              >
                <small>Input must not exceed 140 characters.</small>
                  </span>
                }
              }
            </div>
          </div>
          <div class="md:col-6 pt-3 pb-2">
            <div class="flex flex-column gap-1">
              <span class="p-float-label showAsterisk w-full">
                <input
                  pInputText
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  formControlName="postalCode"
                  class="w-full"
                  placeholder=" "
                  required

                />
                <label>Postal Code</label>
              </span>
              <span class="text-neutral-70 text-xs">5 numbers for postal code</span>
              @if (
                postalCode?.errors && (postalCode?.dirty || postalCode?.touched)
              ) {
                @if (postalCode?.errors?.pattern) {
                  <span class="text-red-60 text-xs">
                    Postal Code should numerical only
                  </span>
                }
                @if (postalCode?.errors?.required) {
                  <span class="text-red-60 text-xs">
                  {{
                    translationKey + 'postalCodeRequired' | translate
                  }}
                  </span>
                }
                @if (
                  postalCode?.errors?.maxlength || postalCode?.errors?.minlength
                ) {
                  <span class="text-red-60 text-xs">
                    Postal Code should be 5 digits
                  </span>
                }
              }
            </div>
          </div>
          <div class="md:col-6 pt-3 pb-2">
            <div class="flex flex-column gap-1">
              <span class="p-float-label w-full">
                <input
                  pInputText
                  type="text"
                  id="buildingNumber"
                  name="buildingNumber"
                  formControlName="buildingNumber"
                  class="w-full"
                  placeholder=" "
                />
                <label>Building Number</label>
              </span>
              @if (
                buildingNumber?.errors && (buildingNumber?.dirty || buildingNumber?.touched)
              ) {
                @if (buildingNumber?.errors?.maxlength) {
                  <span class="text-red-60 text-xs">
                    Building number must not exceed 10 characters.
                  </span>
                }
              }
            </div>
          </div>
          <div class="md:col-6 pt-3 pb-2">
            <div class="flex flex-column gap-1">
              <span class="p-float-label w-full">
                <input
                  pInputText
                  type="text"
                  id="additionalNumber"
                  name="additionalNumber"
                  formControlName="additionalNumber"
                  class="w-full"
                  placeholder=" "

                />
                <label>Additional Number</label>
              </span>
              @if (
                additionalNumber?.errors && (additionalNumber?.dirty || additionalNumber?.touched)
              ) {
                @if (additionalNumber?.errors?.maxlength) {
                  <span class="text-red-60 text-xs">
                    Additional number must not exceed 10 characters.
                  </span>
                }
              }
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
</form>
