<p-dialog
  lass="content-space-0"
  [showHeader]="false"
  [(visible)]="issueCreditNoteInput.dialogVisible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '100%', maxWidth: '982px' }"
>
  <div class="grid grid-nogutter relative">
    @if (loading || issuingCreditNote) {
      <div class="flex justify-content-center spinner">
        <p-progressSpinner
          [style]="{ width: '80px', height: '80px' }"
        ></p-progressSpinner>
      </div>
    }
    <div class="col-6 p-5">
      <div class="flex flex-column gap-3">
        <label class="text-2xl font-medium"
          >{{ 'Issue Credit Note' | translate }}
        </label>
        <label
          *ngIf="!isCharges"
          class="text-base font-medium pt-4"
        >
          {{ 'Select Refund Type' | translate }}
        </label>
        <div class="flex flex-column gap-4">
          <form
            [formGroup]="formGroup"
            class="flex flex-column gap-4"
          >
            @if (!isCharges) {
              <div class="flex w-full">
                <span class="w-full m-0 p-0">
                  <p-selectButton
                    [options]="refundOptions"
                    formControlName="refundValue"
                    optionLabel="label"
                    optionValue="value"
                    (onChange)="handleSelectChange($event)"
                  />
                </span>
              </div>
              <div class="text-neutral-70">
                @for (sub of creditNoteSubtitle; track sub.amount) {
                  @if (
                    isPartial &&
                    refundValue === REFUND_VALUE_CONST.Full &&
                    !refundType
                  ) {
                  } @else {
                    <span class="fw-700">
                      {{ sub.amount | number: '1.2-2' }}
                      SAR
                    </span>
                    <span>{{ sub.text }} </span>
                    <br *ngIf="creditNoteSubtitle.length > 1" />
                  }
                }
              </div>
            }

            <hr
              class="border-neutral-20 border-top-1 border-bottom-none border-x-0 m-0"
            />
            <label
              *ngIf="isCharges"
              class="text-base font-medium"
            >
              {{ 'Select Refund Reason' | translate }}
            </label>
            <!-- Refund Reason-->
            <div class="flex">
              <span class="showAsterisk w-full">
                <p-dropdown
                  styleClass="w-full"
                  [options]="refundReasonsDropdownItems"
                  formControlName="refundReason"
                  placeholder="Select a Refund Reason"
                >
                </p-dropdown>
              </span>
            </div>
            <!-- Refund Type-->
            @if (isPartial && refundValue === REFUND_VALUE_CONST.Full) {
              <div class="flex">
                <span class="showAsterisk w-full">
                  <p-dropdown
                    styleClass="w-full"
                    [options]="refundTypesDropdownItems"
                    formControlName="refundType"
                    placeholder="Select Refund Type"
                  >
                  </p-dropdown>
                </span>
              </div>
            }

            <hr
              class="border-neutral-20 border-top-1 border-bottom-none border-x-0 m-0"
            />

            <div class="flex">
              <label class="text-neutral-70 font-medium"
                >Credit Note Issue Date
                <span class="text-neutral-90">{{
                  creditNoteIssueDate | date: 'dd/MM/yyyy'
                }}</span>
              </label>
            </div>

            <rwa-info
              *ngIf="!isCharges"
              [icon]="'fa-regular fa-triangle-exclamation'"
              [type]="'warning'"
              [translationKey]="''"
              [text]="
                '<b>Note:</b> Issuing a credit note for an active subscription will lead to cancellation.'
              "
            ></rwa-info>

            <div class="flex flex-column gap-1">
              <textarea
                class="textarea-resize-none"
                placeholder="Notes"
                rows="5"
                pInputTextarea
                formControlName="notes"
              >
              </textarea>
              @if (notes?.errors?.maxlength) {
                <span class="text-red-60 text-xs">
                  <small>Input must not exceed 500 characters.</small>
                </span>
              }
              <div
                class="flex align-items-center justify-content-end text-neutral-70 text-xs"
              >
                {{ getRefundNoteValue().length }}/500
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-6 p-5 bg-neutral-10 border-neutral-20 border-left-1">
      <div class="flex flex-column gap-4">
        <div class="flex align-items-center justify-content-between">
          <label class="text-base text-neutral-70 font-medium">{{
            'Credit Note Summary' | translate
          }}</label>
          <p-button
            icon="fa-regular fa-xmark"
            [rounded]="true"
            [text]="true"
            (click)="close()"
          />
        </div>
        <div class="flex flex-column">
          <div class="rwa__datatable-transparent">
            <p-table
              [value]="productList"
              styleClass="text-sm"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left">{{ 'Product Name' | translate }}</th>
                  <th class="text-center">{{ 'Quantity' | translate }}</th>
                  <th class="text-right">
                    {{ 'Adjusted Price' | translate }}
                  </th>
                  <th class="text-right">{{ 'Total' | translate }}</th>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-product
              >
                <tr>
                  <td class="text-left">{{ product.name | translate }}</td>
                  <td class="text-center">{{ product.quantity }}</td>
                  <td class="text-right">{{ product.adjustedPrice }}</td>
                  <td class="text-right">{{ product.totalPrice }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          @if (proratedCreditNote && !proratedCreditNote.refundable) {
            <div
              class="flex align-items-center justify-content-center gap-2 w-full py-4"
            >
              <label class="font-medium">{{
                'No Refundable Credit Notes will be made' | translate
              }}</label>
            </div>
          }
          <hr
            class="border-neutral-20 border-top-1 border-bottom-none border-x-0 m-0"
          />
          <div class="flex justify-content-end mt-3">
            <div class="flex flex-column gap-3 w-9">
              <div
                class="flex text-sm align-items-center justify-cotnent-between text-neutral-90 w-full"
              >
                <div class="flex align-items-center gap-2 w-full">
                  <label class="font-medium">{{
                    'Subtotal' | translate
                  }}</label>
                  <span class="text-neutral-70"
                    >({{ 'Tax Exclusive' | translate }})</span
                  >
                </div>
                <span>{{ invoiceData.subtotal | number: '1.2-2' }}</span>
              </div>
              <div
                class="flex text-sm align-items-center justify-cotnent-between text-neutral-90 w-full"
              >
                <div class="flex align-items-center gap-2 w-full">
                  <label class="font-medium">{{
                    'Discount' | translate
                  }}</label>
                  <span class="text-neutral-70"
                    >({{ 'Tax Exclusive' | translate }})</span
                  >
                </div>
                <span>{{ invoiceData.discount | number: '1.2-2' }}</span>
              </div>
              <div
                class="flex text-sm align-items-center justify-cotnent-between text-neutral-90 w-full"
              >
                <div class="flex align-items-center gap-2 w-full">
                  <label class="font-medium">{{
                    'Promotions' | translate
                  }}</label>
                  <span class="text-neutral-70"
                    >({{ 'Tax Exclusive' | translate }})</span
                  >
                </div>
                <span>{{ invoiceData.promotions | number: '1.2-2' }}</span>
              </div>
              <div
                class="flex text-sm align-items-center justify-cotnent-between text-neutral-90 w-full"
              >
                <div class="flex align-items-center gap-2 w-full">
                  <label class="font-medium">{{ 'Taxes' | translate }}</label>
                </div>
                <span>{{ invoiceData.tax | number: '1.2-2' }}</span>
              </div>
              @if (refundValue === REFUND_VALUE_CONST.Prorated) {
                <div
                  class="flex text-sm align-items-center justify-cotnent-between text-neutral-90 w-full"
                >
                  <div class="flex align-items-center gap-2 w-full">
                    <label class="font-medium">{{
                      'Adjustment Credit' | translate
                    }}</label>
                  </div>
                  <span>{{
                    adjustmentCreditNoteAmount | number: '1.2-2'
                  }}</span>
                </div>
              }
              <hr
                class="border-neutral-20 border-top-1 border-bottom-none border-x-0 m-0"
              />
              <div
                class="flex text-sm align-items-center justify-cotnent-between text-neutral-90 w-full"
              >
                <div class="flex align-items-center gap-2 w-full">
                  <label class="font-medium">{{ 'Total' | translate }}</label>
                  <span class="text-neutral-70"
                    >({{ 'Tax Inclusive' | translate }})</span
                  >
                </div>
                <span>
                  {{ invoiceData.total | number: '1.2-2' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex align-items-center justify-content-end gap-3">
      <p-button
        label="{{ 'Cancel' | translate }}"
        [text]="true"
        [disabled]="loading"
        (click)="close()"
      ></p-button>
      <p-button
        label="{{ 'Issue Credit Note' | translate }}"
        [disabled]="loading || formGroup?.invalid || issuingCreditNote"
        (click)="handleIssueCreditNote()"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
