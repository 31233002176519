import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DropdownFilterOptions } from 'primeng/dropdown';
import {
  Component,
  EventEmitter,
  Input,
  model,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  InvoiceDtoWithValidity,
  MapAdditionalIdentifier,
  MappedBillingDocument,
} from '../../../../accounts/types';
import { CustomToastService } from '../../../services/custom-toast.service';
import {
  additionalIdentifierOptions,
  cityListsByCountry,
  countriesList,
  countryCode,
} from '../../../../accounts/constants';
import { RegexEnum } from '../../../model/enums/RegexPattern';
import { AccountService } from '../../../../accounts/account.service';
import { ReSubmitToZatcaInput } from '../../../types';
import { getPhoneNumberValidator } from '../../../utils';

@Component({
  selector: 'rwa-billing-info-dialog',
  templateUrl: './billing-info-dialog.component.html',
  styleUrl: './billing-info-dialog.component.scss',
})
export class BillingInfoDialogComponent implements OnInit {
  readonly translationKey = 'createInvoice.';

  selectedIdentifier = null;

  showCustomCityNameField = false;

  // @Input() billingInfoDialogInput: MappedBillingDocument | PartialDocument;

  @Input() billingInfoDialogInput: ReSubmitToZatcaInput;

  invoiceData = model.required<InvoiceDtoWithValidity>();

  showModal: null | string = null;

  filteredCities = [];

  selectedCountry = { name: 'المملكة العربية السعودية' };

  selectedCity = { name: '' };

  cities = cityListsByCountry['المملكة العربية السعودية'];

  countries = countriesList;

  customerForm: UntypedFormGroup;

  additionalIdentifiers = additionalIdentifierOptions;

  lang: string;

  countriesWithData: any[] = [
    {
      countryKey: 'SA',
      name: 'Saudi Arabia',
      localeName: 'Saudi Arabia',
      code: '966',
      currency: 'SAR',
      languages: 'ar',
      createdAt: null,
      deletedAt: null,
      updatedAt: null,
    },
  ];

  phoneSelectedCountry: any = {
    countryKey: 'SA',
    name: 'Saudi Arabia',
    localeName: 'Saudi Arabia',
    code: '966',
    currency: 'SAR',
    languages: 'ar',
    createdAt: null,
    deletedAt: null,
    updatedAt: null,
  };

  isPhoneNumberAvailable: boolean = false;

  filterValue = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() closeDialog = new EventEmitter<any>();

  constructor(
    public fb: UntypedFormBuilder,
    public accountService: AccountService,
    private translate: TranslateService,
    public customToastService: CustomToastService,
  ) {
    this.customerForm = this.fb.group({
      vatNumber: [
        '',
        [
          Validators.maxLength(15),
          Validators.required,
          Validators.pattern(RegexEnum.numberOnly),
        ],
      ],
      nonTaxableEntity: [false],
      country: ['', [Validators.required]],
      city: ['', [Validators.required, this.cityValidator.bind(this)]],
      customCityName: [''],
      districtName: ['', [Validators.required, Validators.maxLength(149)]],
      streetName: ['', [Validators.required, Validators.maxLength(139)]],
      postalCode: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
          Validators.pattern(RegexEnum.numberOnly),
        ],
      ],
      companyName: ['', [Validators.required, Validators.maxLength(149)]],
      phone: [''],
      additionalIdentifier: ['', Validators.required],
      additionalIdentifierNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(RegexEnum.numberOnly),
        ],
      ],
      buildingNumber: ['', Validators.maxLength(10)],
      additionalNumber: ['', Validators.maxLength(10)],
    });
  }

  ngOnInit(): void {
    this.onFormValueChanges();
    this.selectedCountry = { name: 'المملكة العربية السعودية' };
    this.cities = cityListsByCountry[this.selectedCountry.name];

    const document = this.billingInfoDialogInput
      .document as MappedBillingDocument;
    if (document.invoice_type === 'B2C') {
      this.customerForm.get('vatNumber')?.clearValidators();
      this.customerForm.get('vatNumber')?.disable();
      this.customerForm.get('vatNumber')?.patchValue('');

      this.customerForm.get('additionalIdentifier')?.clearValidators();
      this.customerForm.get('additionalIdentifier')?.disable();
      this.customerForm.get('additionalIdentifier')?.patchValue('');

      this.customerForm.get('additionalIdentifierNumber')?.clearValidators();
      this.customerForm.get('additionalIdentifierNumber')?.disable();
      this.customerForm.get('additionalIdentifierNumber')?.patchValue('');
    }

    if (!document.phoneNumber) {
      this.isPhoneNumberAvailable = false;
      this.customerForm
        .get('phone')
        ?.setValidators([
          Validators.required,
          getPhoneNumberValidator(this.phoneSelectedCountry),
        ]);
      this.customerForm.get('phone').updateValueAndValidity();
      firstValueFrom(this.accountService.getCountries())
        .then((countries) => {
          this.countriesWithData = countries
            .map((country) => {
              const localeName =
                this.lang === 'ar'
                  ? this.translate.instant(`country.${country.countryKey}`)
                  : country.name;
              const countryWithLocale = { ...country, localeName };
              if (
                countryWithLocale.countryKey === 'SA' &&
                !this.phoneSelectedCountry
              ) {
                this.phoneSelectedCountry = countryWithLocale;
              }
              return countryWithLocale;
            })
            .sort((a, b) => a.localeName.localeCompare(b.localeName));
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.info(error);
          this.customToastService.error(error);
        });
    } else {
      this.isPhoneNumberAvailable = true;
    }

    this.invoiceData.set({
      isValid: this.isFormValid(),
      vatNumber: document.vatNumber,
      companyName: document.billingAddress.company,
      additionalIdentifier: MapAdditionalIdentifier[document.additionalIdType],
      additionalIdentifierNumber: document.additionalIdValue,
      city: document.billingAddress.city,
      country: document.billingAddress.country,
      streetName: document.billingAddress.line1?.split(', ')[0],
      postalCode: document.billingAddress.zip,
      districtName: document.billingAddress.line2,
      additionalNumber: document.billingAddress.line1?.split(', ')[1] || '',
      buildingNumber: document.billingAddress.line3 || '',
      webhookTimestamp: +document.webhookTimestamp,
      email: document.email,
      customerId: document.customerId,
    });

    this.invoiceData.set({
      ...this.invoiceData(),
      isValid: this.isFormValid(),
    });

    this.fillDetailsAndDisableFieldsInForm(this.invoiceData());

    this.customerForm
      .get('additionalIdentifier')
      ?.valueChanges.subscribe((value) => {
        this.handleAdditionalIdentifierChange(value);
      });

    this.customerForm.get('city').valueChanges.subscribe((value) => {
      this.handleCityChange(value);
    });
  }

  onFormValueChanges(): void {
    this.customerForm.valueChanges.subscribe(() => {
      this.invoiceData.set({
        isValid: this.isFormValid(),
        vatNumber: this.vatNumber?.value,
        companyName: this.companyName?.value,
        additionalIdentifier: this.additionalIdentifier?.value,
        additionalIdentifierNumber: this.additionalIdentifierNumber?.value,
        country: this.selectedCountry.name,
        countryCode: countryCode[this.customerForm.get('country')?.value.name],
        city:
          this.customerForm.get('customCityName')?.value ||
          this.city?.value?.name,
        districtName: this.districtName?.value ?? '',
        streetName: this.streetName?.value,
        postalCode: this.postalCode?.value,
        additionalNumber: this.additionalNumber?.value,
        buildingNumber: this.buildingNumber?.value,
        webhookTimestamp: +(
          this.billingInfoDialogInput.document as MappedBillingDocument
        ).webhookTimestamp,
        email: (this.billingInfoDialogInput.document as MappedBillingDocument)
          .email,
        customerId: (
          this.billingInfoDialogInput.document as MappedBillingDocument
        ).customerId,
        phoneNumber: this.phone.value
          ? `+${this.phoneSelectedCountry.code}${this.phone.value}`
          : '',
      });
      // console.log(this.invoiceData());
    });
  }

  get vatNumber(): AbstractControl | null {
    return this.customerForm.get('vatNumber');
  }

  get nonTaxableEntity(): AbstractControl | null {
    return this.customerForm.get('nonTaxableEntity');
  }

  get city(): AbstractControl | null {
    return this.customerForm.get('city');
  }

  get customCityName(): AbstractControl | null {
    return this.customerForm.get('customCityName');
  }

  get country(): AbstractControl | null {
    return this.customerForm.get('country');
  }

  get districtName(): AbstractControl | null {
    return this.customerForm.get('districtName');
  }

  get streetName(): AbstractControl | null {
    return this.customerForm.get('streetName');
  }

  get postalCode(): AbstractControl | null {
    return this.customerForm.get('postalCode');
  }

  get companyName(): AbstractControl | null {
    return this.customerForm.get('companyName');
  }

  get additionalIdentifier(): AbstractControl | null {
    return this.customerForm.get('additionalIdentifier');
  }

  get additionalIdentifierNumber(): AbstractControl | null {
    return this.customerForm.get('additionalIdentifierNumber');
  }

  get buildingNumber(): AbstractControl | null {
    return this.customerForm.get('buildingNumber');
  }

  get additionalNumber(): AbstractControl | null {
    return this.customerForm.get('additionalNumber');
  }

  get phone(): AbstractControl | null {
    return this.customerForm.get('phone');
  }

  myResetFunction(options: DropdownFilterOptions): void {
    options.reset();
    this.filterValue = '';
  }

  filterCities(event: { query: string }): void {
    const filtered: { name: string }[] = [];
    const { query } = event;
    for (let i = 0; i < this.cities.length; i++) {
      const city = this.cities[i];
      if (city.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        filtered.push(city);
      }
    }
    this.filteredCities = filtered;
  }

  handleVATToggle(): void {
    const isTaxable = this.customerForm.get('nonTaxableEntity')?.value;
    const vatNumber = 'vatNumber';

    if (isTaxable) {
      this.customerForm.get(vatNumber)?.clearValidators();
      this.customerForm.get(vatNumber)?.disable();
      this.customerForm.get(vatNumber)?.patchValue('');
    } else {
      this.customerForm
        .get(vatNumber)
        ?.setValidators([
          Validators.required,
          Validators.maxLength(15),
          Validators.pattern(RegexEnum.numberOnly),
        ]);
      this.customerForm.get(vatNumber)?.enable();
    }
    this.customerForm.get(vatNumber)?.updateValueAndValidity();
  }

  handleAdditionalIdentifierChange(value: string): void {
    const additionalIdentifierNumber = this.customerForm.get(
      'additionalIdentifierNumber',
    );

    if (value === "Client doesn't have a legal entity") {
      additionalIdentifierNumber?.disable();
      additionalIdentifierNumber?.setValue('');
    } else {
      additionalIdentifierNumber?.enable();

      additionalIdentifierNumber?.setValidators([
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(10),
        Validators.pattern(RegexEnum.numberOnly),
      ]);

      additionalIdentifierNumber?.updateValueAndValidity();
    }
  }

  handleCityChange(value: { name: string } | string): void {
    if (typeof value === 'object' && value?.name === 'أخرى') {
      this.showCustomCityNameField = true;

      if (!this.customerForm.get('customCityName')) {
        this.customerForm.addControl(
          'customCityName',
          new FormControl('', [Validators.required, Validators.maxLength(49)]),
        );
      }
    } else {
      this.showCustomCityNameField = false;

      if (this.customerForm.get('customCityName')) {
        this.customerForm.removeControl('customCityName');
      }
    }
  }

  updateAvailableCities(): void {
    this.cities = cityListsByCountry[this.selectedCountry.name];
    if (this.cityValidator(this.city))
      this.customerForm.get('city')?.patchValue('');
  }

  cityValidator(control: AbstractControl): { [key: string]: unknown } | null {
    const validCity = this.cities.find(
      (includedCity) => includedCity.name === control.value?.name,
    );
    return validCity ? null : { invalidCity: { value: control.value } };
  }

  checkIfCityIsValid(cityName: string): boolean {
    const validCity = this.cities.find(
      (includedCity) => includedCity.name === cityName,
    );
    return !!validCity;
  }

  fillDetailsAndDisableFieldsInForm(info: InvoiceDtoWithValidity): void {
    if (info.country) {
      this.selectedCountry = { name: info.country };
      this.updateAvailableCities();
    }

    if (info.city) {
      if (this.checkIfCityIsValid(info.city)) {
        this.showCustomCityNameField = false;

        if (this.customerForm.get('customCityName')) {
          this.customerForm.removeControl('customCityName');
        }

        this.customerForm.patchValue({
          city: { name: info.city },
        });
      } else {
        this.showCustomCityNameField = true;
        this.customerForm.patchValue({
          city: { name: 'أخرى' },
          customCityName: info.city,
        });
      }
    }

    if (info.additionalIdentifierNumber) {
      this.customerForm.patchValue({
        additionalIdentifierNumber: info.additionalIdentifierNumber.toString(),
      });
    }

    this.customerForm.patchValue({
      country: this.selectedCountry,
      districtName: info.districtName,
      streetName: info.streetName,
      postalCode: info.postalCode,
      buildingNumber: info.buildingNumber,
      additionalNumber: info.additionalNumber,
      companyName: info.companyName,
    });

    if (info.additionalIdentifier === 'Commercial Registration') {
      this.customerForm.patchValue({
        additionalIdentifier: info.additionalIdentifier,
      });
    }

    if (info.vatNumber) {
      this.customerForm.patchValue({
        vatNumber: info.vatNumber,
      });
    } else {
      this.customerForm.get('vatNumber')?.disable();
      this.customerForm.get('nonTaxableEntity')?.setValue(true);
    }

    this.customerForm.get('country')?.disable();

    this.invoiceData.set({
      ...info,
      isValid: this.isFormValid(),
    });
  }

  close(): void {
    this.closeDialog.emit();
  }

  isFormValid(): boolean {
    for (const controlName in this.customerForm.controls) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.customerForm.controls,
          controlName,
        )
      ) {
        const control = this.customerForm.get(controlName);
        if (control?.enabled && control.invalid) {
          return false;
        }
      }
    }
    return true;
  }

  onCountrySelect(): void {
    this.phone.setValue('');
  }

  // trigger deployment
}
